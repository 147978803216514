import styled from 'styled-components';
import { offWhite } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  max-width: 1400px;
  padding: 4rem 4rem;
  margin: 3rem auto;
  border-radius: 34px;
  background-color: ${offWhite};
  text-align: center;

  ${MEDIA.TABLET`
      padding: 2rem 1rem;
  `};

  ${MEDIA.PHONE`
      padding: 2rem 0rem;
      /* border-radius: 0px; */
  `};
`;
