import styled from 'styled-components';
import {
  gradStart,
  gradMid,
  gradEnd,
  redGradStart,
  redGradEnd,
} from 'constants/theme';

export const Button = styled.div`
  background: ${props =>
    props.red
      ? 'linear-gradient(90deg, ' + redGradStart + ',' + redGradEnd + ')'
      : 'linear-gradient(90deg, ' +
        gradStart +
        ',' +
        gradMid +
        ',' +
        gradEnd +
        ')'};
  border-radius: 999px;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1;
  padding: 1.55rem 4rem;
  position: relative;
  text-align: center;
  transition: box-shadow 0.2s ease-out;
  white-space: nowrap;
  width: 100%;
`;
